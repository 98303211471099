import { template as template_80690b2eb5d34038b0f7c1b2f3e10050 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_80690b2eb5d34038b0f7c1b2f3e10050(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
