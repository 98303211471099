import { template as template_b2a431ee7de14da9a9436b171ce6128b } from "@ember/template-compiler";
const FKText = template_b2a431ee7de14da9a9436b171ce6128b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
