import { template as template_e375f628eb444f1f912c3ec4d5b20370 } from "@ember/template-compiler";
const SidebarSectionMessage = template_e375f628eb444f1f912c3ec4d5b20370(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
